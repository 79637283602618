/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import store from '../store/index';
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(Vuex);
Vue.use(VueRouter);

function guard(to, from, next) {
  if (store.state.auth.loggedIn) {
    if (to.name === 'Login') {
      next('/home');
    } else {
      next(); // allow to enter route
    }
  } else {
    if (to.name !== 'Login') {
      next('/login');
    } else {
      next(); // allow to enter route
    }
  }
}

const routes = [
  {
    path: '*',
    meta: {
      pageTitle: 'No Component Found'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/noComponentFound.vue")
  },
  {
    path: "/",
    redirect: to => {
      return '/login';
    }
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      pageTitle: 'Login',
      public: true,
    },
    // beforeEnter: guard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue")
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    meta: {
      pageTitle: 'Forget Password',
      public: true,
    },
    // beforeEnter: guard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ForgetPassword" */ "../views/forget-password.vue")
  },
  {
    path: "/password/reset",
    name: "password.reset",
    meta: {
      pageTitle: 'Reset Password',
      public: true,
    },
    // beforeEnter: guard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ForgetPassword" */ "../views/reset-password.vue")
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      breadcums: {
        title: 'Home',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Home',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/dashboard/dashboard_index.vue")
  },
  {
    path: "/petrol-station",
    name: "Petrol Station",
    meta: {
      breadcums: {
        title: 'Petrol Station',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Stations',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "petrol-station" */ "../views/petrol-station/index")
  },

  {
    path: "/petrol-station/create",
    name: "Petrol Station - create",
    meta: {
      breadcums: {
        title: 'Petrol Station - Create',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'CreateStations',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "CreateStations" */ "../views/petrol-station/create/index.vue")
  },


  {
    path: "/petrol-station/:station_id",
    name: "Petrol Station Detail Page",
    meta: {
      breadcums: {
        title: 'Petrol Station Detail Page',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Stations',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "petrol-station" */ "../views/petrol-station/show/index")
  },


  {
    path: "/tanks",
    name: "Tanks",
    meta: {
      breadcums: {
        title: 'Tanks',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Tanks',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Tanks" */ "../views/tanks/index")
  },

  {
    path: "/stocks",
    name: "Stocks",
    meta: {
      breadcums: {
        title: 'Stocks',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Stocks',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Stocks" */ "../views/stocks/index")
  },

  {
    path: "/admin",
    name: "Admin",
    meta: {
      breadcums: {
        title: 'Admin',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Admin',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Admin" */ "../views/admin/index")
  },

  {
    path: "/admin/create",
    name: "Admin - Create",
    meta: {
      breadcums: {
        title: 'Admin - Create',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Admin',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "CreateAdmin" */ "../views/admin/create/index")
  },

  {
    path: "/admin/edit/:admin_id",
    name: "Admin - Edit",
    meta: {
      breadcums: {
        title: 'Admin - Edit',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Admin',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "EditAdmin" */ "../views/admin/edit/index")
  },


  {
    path: "/vendor",
    name: "Vendor",
    meta: {
      breadcums: {
        title: 'Vendor',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Vendor',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Vendor" */ "../views/vendor/index")
  },

  {
    path: "/vendor/create",
    name: "Vendor - Create",
    meta: {
      breadcums: {
        title: 'Vendor - Create',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Vendor',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "CreateVendor" */ "../views/vendor/create/index")
  },

  {
    path: "/vendor/edit/:vendor_id",
    name: "Vendor - Edit",
    meta: {
      breadcums: {
        title: 'Vendor - Edit',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Admin',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "EditVendor" */ "../views/vendor/edit/index")
  },


  {
    path: "/premium-clients",
    name: "Premium Clients",
    meta: {
      breadcums: {
        title: 'Premium Clients',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Premium Clients',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "PremiumClients" */ "../views/premium-clients/index")
  },

  {
    path: "/premium-clients/create",
    name: "Premium Clients - Create",
    meta: {
      breadcums: {
        title: 'Premium Clients - Create',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Premium Clients - Create',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "PremiumClients" */ "../views/premium-clients/create/index")
  },

  {
    path: "/premium-clients/edit/:premium_client_id",
    name: "Premium Clients - Edit",
    meta: {
      breadcums: {
        title: 'Premium Clients - Edit',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'remium Clients - Edit',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "PremiumClient" */ "../views/premium-clients/edit/index")
  },

  {
    path: "/premium-clients/show/:premium_client_id",
    name: "Premium Clients - Show",
    meta: {
      breadcums: {
        title: 'Premium Clients - Show',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'remium Clients - Show',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "PremiumClient" */ "../views/premium-clients/show/index")
  },

  // Premium client order
  {
    path: "/premium-clients-order",
    name: "Premium Clients Order",
    meta: {
      breadcums: {
        title: 'Premium Clients Order',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Premium Clients Order',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "PremiumClients" */ "../views/premium-clients-order/index")
  },

  {
    path: "/premium-clients-order/create",
    name: "Premium Clients Order - Create",
    meta: {
      breadcums: {
        title: 'Premium Clients Order - Create',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Premium Clients Order - Create',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "PremiumClients" */ "../views/premium-clients-order/create/index")
  },

  {
    path: "/premium-clients-order/edit/:premium_client_id",
    name: "Premium Clients Order - Edit",
    meta: {
      breadcums: {
        title: 'Premium Clients Order - Edit',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Premium Clients Order',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "PremiumClient" */ "../views/premium-clients-order/edit/index")
  },

  {
    path: "/cashier",
    name: "Cashier",
    meta: {
      breadcums: {
        title: 'Cashier',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Cashier',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Cashier" */ "../views/cashier/index")
  },

  {
    path: "/cashier/create",
    name: "Cashier - Create",
    meta: {
      breadcums: {
        title: 'Cashier - Create',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Cashier',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Cashier" */ "../views/cashier/create/index")
  },

  {
    path: "/cashier/edit/:cashier_id",
    name: "Cashier - Edit",
    meta: {
      breadcums: {
        title: 'Cashier - Edit',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Cashier',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Cashier" */ "../views/cashier/edit/index")
  },

  {
    path: "/shifts",
    name: "Shifts",
    meta: {
      breadcums: {
        title: 'Shifts',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Shifts',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Shifts" */ "../views/shifts/index")
  },

  {
    path: "/shifts/end-shift/:shift_id",
    name: "Shifts - End Shift",
    meta: {
      breadcums: {
        title: 'Shifts - End Shift',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Shifts - End Shift',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "EndShifts" */ "../views/shifts/show/index")
  },

  {
    path: "/shifts/edit-shift/:shift_id",
    name: "Shifts - Edit Shift",
    meta: {
      breadcums: {
        title: 'Shifts - Edit Shift',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Shifts - Edit Shift',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "EndShifts" */ "../views/shifts/edit/index")
  },

  {
    path: "/shifts/shift-details/:shift_id",
    name: "Shifts - Shift Details",
    meta: {
      breadcums: {
        title: 'Shifts - Shift Details',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Shifts - Shift Details',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ShiftDetails" */ "../views/shifts/details/index")
  },

  {
    path: "/orders",
    name: "Orders",
    meta: {
      breadcums: {
        title: 'Orders',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Orders',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Orders" */ "../views/orders/index")
  },
  {
    path: "/orders/create",
    name: "Orders - create",
    meta: {
      breadcums: {
        title: 'Orders - Create',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'CreateStations',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "CreateOrder" */ "../views/orders/create/index.vue")
  },
  {
    path: "/orders/show/:order_id",
    name: "Orders Detail",
    meta: {
      breadcums: {
        title: 'Orders Details',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Orders Details',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "OrdersDetails" */ "../views/orders/show/index.vue")
  },
  {
    path: "/expenses",
    name: "Expenses",
    meta: {
      breadcums: {
        title: 'Expenses',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Expenses',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Expenses" */ "../views/expenses/index")
  },

  {
    path: "/shift-sales",
    name: "Shift Sales",
    meta: {
      breadcums: {
        title: 'Shift Sales',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Shift sales',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ShiftSales" */ "../views/shift-sales/index")
  },

  {
    path: "/shift-sales/show/:sale_invoice_id",
    name: "Shift Sale Details",
    meta: {
      breadcums: {
        title: 'Shift Sale Details',
        disabled: true,
        path: '',
        parent: {},
      },
      pageTitle: 'Shift sales',
      public: false,
      progress: {
        func: [
          { call: 'color', modifier: 'temp', argument: '#00FF00' },
          { call: 'fail', modifier: 'temp', argument: '#6e0000' },
          { call: 'location', modifier: 'temp', argument: 'top' },
          { call: 'transition', modifier: 'temp', argument: { speed: '1.5s', opacity: '0.6s', termination: 400 } }
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ShiftSaleDetails" */ "../views/shift-sales/show/index")
  },

];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
